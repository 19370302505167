import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    typography: {
      h6: {
       fontFamily: "Pacifico",
       fontSize : 25,
       fontWeight: "bold"
      }
    }
  })

  export default theme
import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PlayQuiz from "./PlayQuiz";
import SheduleQuiz from "./SheduleQuiz";
import AppDevelopment from "./AppDevelopment";
import Allportfolio from "./Allportfolio";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HowitWork = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Typography
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
        id="portfolio"
        pt={matches ? 15 : 5}
        variant={matches ? "h3" : "h4"}
        textAlign="center"
        sx={{ color: "#001322", fontWeight: 600 }}
      >
        Our Projects
      </Typography>

      <Typography
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
        paddingLeft={1}
        px={matches ? 0 : 3}
        variant="subtitle1"
        textAlign="center"
        sx={{ color: "#001322", fontSize: 18 }}
        mt={3}
      >
       We, as a Leading Software Development Company in USA, set industry standards<br/> by offering the newest technological solutions and services that deliver value,<br/> from strategy consulting to roadmaps.
      </Typography>

      <Box mt={5} sx={{ width: "100%"  }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#001322",
                
              },
            }}
            aria-label="basic tabs example"
          >
            <Tab sx={{fontSize:"22px" ,color:"#001322" }} label="All" {...a11yProps(0)} />
            <Tab sx={{fontSize:"22px" ,color:"#001322" }} label="Web Design" {...a11yProps(1)} />
            <Tab sx={{fontSize:"22px" ,color:"#001322" }}  label="Web Development" {...a11yProps(2)} />
            <Tab  sx={{fontSize:"22px" ,color:"#001322" }} label="App Development " {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Allportfolio />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SheduleQuiz />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PlayQuiz />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AppDevelopment />
        </TabPanel>
      </Box>
    </div>
  );
};

export default HowitWork;

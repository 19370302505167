import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Book from "../../assets/quizMobile.png";
import Pencil from "../../assets/mobile1.png";
import Watch from "../../assets/mobile2.png";
import Dev2 from "../../assets/mobile3.png";

const AppDevelopment = () => {
  return (
    <>
      <Container>
        <Grid container mt={5} spacing={4}>
          <Grid
            item
            md={6}
            xs={12}
            // data-aos="fade-left"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="1000"
          >
            <Grid spacing={4} container>
              <Grid className="containerField" item md={12} xs={12}>
                <img className="portfolioImg" width="100%" src={Dev2} alt="" />
                <div class="overlay">
                  <div class="text">App Development</div>
                </div>
              </Grid>

            </Grid>
            <Grid mt={3} container>
              <Grid className="containerField" item md={12} xs={12}>
                <img
                  className="portfolioImg"
                  width="100%"
                  src={Pencil}
                  alt=""
                />
                <div class="overlay">
                  <div class="text">App Development</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            // data-aos="fade-right"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="1000"
          >
            <div className="containerField">
              <img className="portfolioImg" width="100%" src={Book} alt="" />
              <div class="overlay">
                <div class="text">App Development</div>
              </div>
            </div>

            <Grid className="grid-bottom-right" spacing={4} container>
              <Grid className="containerField" item md={12} xs={12}>
                <img className="portfolioImg" width="100%"  src={Watch} alt="" />
                <div class="overlay">
                  <div class="text">App Development</div>
                </div>
              </Grid>
              {/* <Grid className="containerField" item md={6} xs={6}>
                <img
                  className="portfolioImg"
                  width="100%"
                  src={Handfree}
                  alt=""
                />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AppDevelopment;

import React from "react";
import Typography from "@mui/material/Typography";
import Facebook from "../assets/facebook.png";
import Linkedin from "../assets/linkedin.png";
import AlayzoneLogo from "../assets/alayzoneLogo.png";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <div style={{ backgroundColor: "#001322" }}>
        <div className="footer-content">

        <div>
        <Link
            className="footer-logo-link"
            to="/"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            delay={500}
          >
            <img
              style={{ cursor: "pointer" }}
             className="logo-width"
              src={AlayzoneLogo}
              alt="alayzone-logo"
            />
          </Link>

        </div>
         
          <div className="Social-links">
            <div className="icon">
              <a href="https://www.facebook.com/alayzone">
                <img width="100%" src={Facebook} alt="facebook"></img>
              </a>
            </div>
            <div className="icon">
              <a href="https://www.linkedin.com/company/alayzone/">
                <img width="100%" src={Linkedin} alt="facebook"></img>
              </a>
            </div>
          </div>

          <Typography
            textAlign="center"
            sx={{ color: "white" }}
            variant="subtitle1"
            mt={5}
          >
            © 2022 ALAYZONE - ALL RIGHTS RESERVED
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Footer;

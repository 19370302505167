import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FeaturesCaed from "./FeaturesCard";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Monitor from "../../assets/monitor.png";
import Pencil from "../../assets/pencil.png";
import Presentation from "../../assets/presentation.png";
import Strategy from "../../assets/strategy.png";
import Settings from "../../assets/settings.png";
import Tools from "../../assets/tools.png";

const KeyFeatures = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <div  style={{ backgroundColor: "#001322" }} id="service">
        <Typography
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          pt={matches ? 10 : 10}
          variant={matches ? "h3" : "h4"}
          textAlign="center"
          sx={{ color: "white", fontWeight: "600" }}
        >
          What We Do ?
        </Typography>
        <Typography
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          mt={3}
          mb={matches ? 10 : 5}
          px={matches ? 0 : 3}
          variant="subtitle1"
          textAlign="center"
          sx={{ color: "white" , fontSize:18 }}
        >
          We have high quality standards that distinct us from the rest of the
          market.
        </Typography>

        <div
          className="keyfeaturebgpadding"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <Container>
            <Grid container spacing={5}>
              <Grid  item md={4} xs={12}>
                <FeaturesCaed 
                  title="Web Design"
                  content="The first thing that brings someone to your app is a beautiful, original and modern look and feel."
                  imgAvatar={Monitor}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FeaturesCaed
                  title="Web & App Development"
                  content="We create brilliant websites & applications that are manageable, fully functional, secure, and scalable enough to help you separate yourself from the competition."
                  imgAvatar={Pencil}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FeaturesCaed
                  title="Digital Marketing"
                  content="We offer digital marketing services to promote your business online with a target audience, competitor analysis."
                  imgAvatar={Presentation}
                />
              </Grid>
            </Grid>

            <Grid mt={1} pb={10} container spacing={5}>
              <Grid item md={4} xs={12}>
                <FeaturesCaed title="Strategy" content="Experienced startup founders who have built and managed tier-one engineering teams, raised capital, delivered products, and sold companies." imgAvatar={Strategy} />
              </Grid>
              <Grid item md={4} xs={12}>
                <FeaturesCaed title="Support" content="We know the difference between success and excellence which is why we only provide professional work. To ensure credibility, we offer QA & testing as a separate service" imgAvatar={Settings} />
              </Grid>
              <Grid item md={4} xs={12}>
                <FeaturesCaed title="We’re experienced" content="Our talented and creative developer and designers understanding all concepts bring together the professional user experience and top notch technology that solve business challenges and enables strategic growth." imgAvatar={Tools} />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default KeyFeatures;

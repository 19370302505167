import React from "react";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import Briefcase from "../../assets/briefcase.png";
import Smile from "../../assets/smile.png";
import Calendar from "../../assets/calendar.png";
import Trophy from "../../assets/trophy.png";
import { Box } from "@mui/system";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const TextbookSelect = () => {
  return (
    <>
      <div>
        <Container>
          <div
            className="all-fields"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <div className="each-field">
              <Box my={2} className="field-icon-box">
                <img className="field-icon" src={Briefcase} alt="" />
              </Box>
              <div className="field-text">
                <Typography
                  my={2}
                  sx={{ fontSize: "36px", fontWeight: "600", color: "#1583FF" }}
                  variant="h3"
                >
                +
                  <CountUp end={200} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    paddingTop: "5px",
                    fontWeight: 500,
                    color: "#797979",
                  }}
                  variant="h4"
                >
                  Total Projects
                </Typography>
              </div>
            </div>
            <div className="each-field">
              <Box my={2} className="field-icon-box">
                <img className="field-icon" src={Smile} alt="" />
              </Box>
              <div className="field-text">
                <Typography
                  my={2}
                  sx={{ fontSize: "36px", fontWeight: "600", color: "#1583FF" }}
                  variant="h3"
                >
                +
                  <CountUp end={100} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    paddingTop: "5px",
                    fontWeight: 500,
                    color: "#797979",
                  }}
                  variant="h4"
                >
                  Happy Clients
                </Typography>
              </div>
            </div>
            <div className="each-field">
              <Box my={2} className="field-icon-box">
                <img className="field-icon" src={Calendar} alt="" />
              </Box>
              <div className="field-text">
                <Typography
                  my={2}
                  sx={{ fontSize: "36px", fontWeight: "600", color: "#1583FF" }}
                  variant="35"
                >
                +
                  <CountUp end={5} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                   Years
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    paddingTop: "5px",
                    fontWeight: 500,
                    color: "#797979",
                  }}
                  variant="h4"
                >
                  Experience
                </Typography>
              </div>
            </div>
            <div className="each-field">
              <Box my={2} className="field-icon-box">
                <img className="field-icon" src={Trophy} alt="" />
              </Box>
              <div className="field-text">
                <Typography
                  my={2}
                  sx={{ fontSize: "36px", fontWeight: "600", color: "#1583FF" }}
                  variant="h3"
                >
                +
                  <CountUp end={10} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    paddingTop: "5px",
                    fontWeight: 500,
                    color: "#797979",
                  }}
                  variant="h4"
                >
                  Award Won
                </Typography>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TextbookSelect;

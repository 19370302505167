import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const FeaturesCard = ({ title, imgAvatar ,content }) => {
  return (
    <>
      <Box 
        className="doingCard"
        py={5}
        sx={{ backgroundColor: "white", borderRadius: "20px" ,boxShadow : 2 ,height:"370px" }}
      >
        <Box textAlign="center">
          <img className="cardimg" width="20%" src={imgAvatar} alt="" />
        </Box>
        <Typography
          my={3}
          variant="h5"
          textAlign="center"
          sx={{ fontSize: "22px", color: "#1583FF", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography
          px={3}
          variant="subtitle1"
          textAlign="center"
          sx={{ color: "#001322" }}
        >
       {content}
        </Typography>
        <Box my={3} textAlign="center">
          {/* <MoreButton>More</MoreButton> */}
        </Box>
      </Box>
    </>
  );
};

export default FeaturesCard;

import { Box, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import emailIcon from "../../assets/email.png";
import phoneIcon from "../../assets/phone.png";
import Location from "../../assets/location.png";
import addressIcon from "../../assets/address.png";
import { toast } from "react-toastify";
import MyInput from "../global/MyInput.js";
import { db, storage } from "../../Firebase";
import { ref, uploadBytes } from "@firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import MyPopUp from "../global/MyPopUp";
import ReactWhatsapp from "react-whatsapp";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "98.5%",
      margin: "5px",
    },
  },
  form: {
    display: "flex",
  },
}));

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  margin: "10px 0px",
  width: "40%",
  padding: "12px 0px",
  border: "1px solid",
  lineHeight: 1.5,
  letterSpacing: "2px",
  textTransform: "uppercase",
  color: "#040C2C",
  // backgroundColor: '#233C77',
  borderColor: "#040C2C",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#040C2C",
    borderColor: "#040C2C",
    color: "white",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#040C2C",
    borderColor: "#040C2C",
  },
  "&:focus": {},
});

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
  cv: null,
};

const ContactUs = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [file, setFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [errors, setErrors] = useState({});
  const filePickerRef = useRef();
  const [data, setData] = useState(initialData);
  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewFile(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    const isOkay = validations();
    if (!isOkay) {
      return;
    }
    if (!file) return toast.error("Please Upload Your CV");

    await uploadCV();
    await sendData();
    setOpenPopUp(true);
    setData(initialData);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);

    setData({
      ...data,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleInputFile = (e) => {
    const types = "application/pdf";
    let pickedFile;
    pickedFile = e.target.files[0];
    if (!types.includes(pickedFile.type)) {
      return toast.error("Your CV Should be in pdf");
    }
    if (pickedFile.size > 1024 * 1024 * 3) {
      return toast.error("Your File Should Less Then 4 MB");
    }
    setFile(pickedFile);
    setData((data) => {
      return { ...data, cv: pickedFile };
    });
  };

  const validations = (fieldValue = data) => {
    let temp = { ...errors };
    if ("firstName" in fieldValue)
      temp.firstName = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/.test(
        fieldValue.firstName
      )
        ? ""
        : "Please Enter A Valid Name";
    if ("lastName" in fieldValue)
      temp.lastName = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/.test(
        fieldValue.lastName
      )
        ? ""
        : "Please Enter A Valid Name";
    if ("email" in fieldValue)
      temp.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        fieldValue.email
      )
        ? ""
        : "Email is not valid";
    if ("message" in fieldValue)
      temp.message = fieldValue.message ? "" : "This field requires";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const uploadCV = () => {
    if (!file) return;
    const imageRef = ref(storage, `files/${file.name}`);
    uploadBytes(imageRef, file).then(() => {
      setOpenPopUp(true);
    });
  };

  const sendData = async () => {
    const { firstName, lastName, email, message, cv } = data;
    console.log("datasend", data);
    try {
      await addDoc(collection(db, "users"), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message,
        cv: cv.name,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const classes = useStyles();
  return (
    <div id="contact">
      <Container>
        <Grid direction={matches ?  "" : "column-reverse"} container my={15}>
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <Typography
              mb={matches ? 3 : 0}
              variant={matches ? "h3" : "h4"}
              sx={{ color: "#001322", fontWeight: 600 }}
              textAlign={matches ? "left" : "center"}
              mt={matches ? 0 : 4}
            >
              Contact Info
            </Typography>
            <div
             
              className="contactInfo"
            >
              <div className="Info">
                <img className="Contact-icon"  src={addressIcon} alt="" />
                <Typography  ml={3} variant="subtitle1">
                www.alayzone.com
                </Typography>
              </div>
              <div className="Info">
                <img className="Contact-icon"  src={phoneIcon} alt="" />
                <Typography ml={3} variant="subtitle1">
                <ReactWhatsapp className="react-whatsapp" number="+92 306 6648800" message="">
                +92 306 6648800
                </ReactWhatsapp>
               
                </Typography>
              </div>
              <div className="Info">
                <img className="Contact-icon"  src={emailIcon} alt="" />
                <Typography ml={3} variant="subtitle1">
                  alayzone@gmail.com
                </Typography>
              </div>
              <div className="Info">
                <img className="Contact-icon"  src={Location} alt="" />
                <Typography ml={3} variant="subtitle1">
                  Nazim Abad, A block Faisalabad
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <Typography
              className="contactform"
              mb={matches ? 3 : 4}
              variant={matches ? "h3" : "h4"}
              textAlign={matches? "left" : "center"}
              sx={{ color: "#001322", fontWeight: 600 }}
              
            
            >
              Contact Form
            </Typography>

            <form onSubmit={formSubmitHandler} className={classes.root}>
              <div className="field">
                <MyInput
                  className="first"
                  id="outlined-basic"
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  value={data.firstName}
                  error={errors.firstName}
                  onChange={handleInputChange}
                  // required
                />
                <MyInput
                  className="first"
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  error={errors.lastName}
                  value={data.lastName}
                  onChange={handleInputChange}
                />
              </div>

              <MyInput
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                value={data.email}
                error={errors.email}
                onChange={handleInputChange}
                //  required
              />
              <MyInput
                id="outlined-basic"
                label="Message"
                name="message"
                variant="outlined"
                value={data.message}
                error={errors.message}
                onChange={handleInputChange}
                multiline
                rows={4}
              />

              <Stack sx={{ marginLeft: "5px" }} direction="row">
                <BootstrapButton  type="submit">Send Message</BootstrapButton>
                <div className="input-div">
                  <input
                    type="file"
                    ref={filePickerRef}
                    name="file"
                    id="file"
                    class="inputfile"
                    accept=".pdf"
                    onChange={handleInputFile}
                    // required
                  />
                  <label for="file">Upload Your CV</label>
                </div>
              </Stack>
              <div className="upload">{data.cv && <p>{data.cv.name}</p>}</div>
            </form>
          </Grid>
        </Grid>
        <MyPopUp
          title="alayzone"
          openPopUp={openPopUp}
          setOpenPopUp={setOpenPopUp}
        >
          <Typography variant="subtitle1" sx={{ color: "black" }}>
            Your Message is Submit Sucessfully
          </Typography>
        </MyPopUp>
      </Container>
    </div>
  );
};

export default ContactUs;

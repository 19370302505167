import React, { useEffect } from 'react'
import Hero from './Pages/Hero'
import AboutQuizBoz from './Pages/AboutQuizBoz'
import KeyFeatures from './Pages/KeyFeatures'
import TextbookSelect from './Pages/TextbookSelect'
import HowitWork from './Pages/HowitWork'
import UserCarousel from './Pages/UserCarousel'
import TrustedCompany from './Pages/TrustedCompany'
import ContactUs from './Pages/ContactUs'
import AOS from "aos";
import "aos/dist/aos.css";


const Home = () => {
  useEffect(()=>{
      window.scrollTo(0,0)
    },[])

    useEffect(() => {
      AOS.init(
        {
          disable: 'mobile'
        }
      );
    }, []);
  return (
    <div id="/">

     <Hero/>
     <AboutQuizBoz/>
     <KeyFeatures/>
     <HowitWork/>
     <TextbookSelect/>
     <UserCarousel/>
     <TrustedCompany/>
     <ContactUs/>



    </div>
  )
}

export default Home
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcfD-qcElMVpWJFFeAJT43u6mYB7DC0Hg",
  authDomain: "alayzone-e24da.firebaseapp.com",
  projectId: "alayzone-e24da",
  storageBucket: "alayzone-e24da.appspot.com",
  messagingSenderId: "468357540531",
  appId: "1:468357540531:web:aa7538d830626f18c2dec6",
  measurementId: "G-X63Y64C4SG"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const storage = getStorage(app)
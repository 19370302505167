import "./App.css";
import Home from "./Components/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HeaderDrawerResponsive from "./Components/Pages/HeaderDrawerResponsive";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
// import theme from './theme';

function App() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <ToastContainer/>
      <ThemeProvider theme={theme}>
        {matches ? <Header /> : <HeaderDrawerResponsive />}
        <Home />
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;

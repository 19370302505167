import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { IconButton, Typography,DialogActions, styled, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: 'white',
    backgroundColor: '#F89820',
    borderColor: '#F89820',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#F89820',
      borderColor: '#F89820',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F89820',
      borderColor: '#F89820',
    },
    '&:focus': {
      
    },
  });


const MyPopUp = (props) => {
    // const classes = useStyles();
   
    const {title,children,openPopUp,setOpenPopUp} = props
  return (
    <>
    <Dialog open={openPopUp} maxWidth="xs"  >
             <DialogTitle>
            <div style={{display:"flex"}}>
                   <Typography sx={{flexGrow:1}} variant="h5" >{title}</Typography>
                     <IconButton color="secondary"
                     onClick={()=> {setOpenPopUp(false)}}
                       >
                        <CloseIcon />
                     </IconButton>
            </div>
            </DialogTitle>


            <DialogContent dividers >
               {children}
            </DialogContent>
            <DialogActions>
            <BootstrapButton onClick={()=>{setOpenPopUp(false)}} >Ok</BootstrapButton>
            </DialogActions>
    </Dialog>
    </>
  )
}

export default MyPopUp
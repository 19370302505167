import { Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import User3 from "../../assets/user1.jpg";
import User4 from "../../assets/user4.jpg";
import User5 from "../../assets/user5.jpg";
import User2 from "../../assets/user2n.jpg";
import User1 from "../../assets/user3n.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { setting } from "../../common/setting";

const UserCarousel = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <div
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
      >
        <Typography
          variant={matches ? "h3" : "h4"}
          textAlign="center"
          sx={{ color: "#001322", fontWeight: 600, backgroundColor: "white" }}
        >
          Our Happy Clients
        </Typography>
        <div className="CarouselIMg">
          <Slider className="slider" {...setting}>
            <div style={{ display: "flex" }} className="userParrent">
              <div className="img-container">
                <img className="userimg" width="100%" src={User1} alt="" />
              </div>

              <Typography
                mt={1}
                sx={{ color: "white" }}
                variant={matches ? "h5" : "h7"}
              >
                Emith Buchen
              </Typography>
              <Typography
                my={1}
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              >
                Product Manager
              </Typography>
              <Typography
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              >
                "Alayzone is amazing. Not only did they deliver what was
                promised on time and on cost, but they also went above and
                beyond. {matches ? <br/> : ""}Just hire them and stop looking. I would 100% use them
                again."
              </Typography>
            </div>
            <div className="userParrent">
              <div className="img-container">
                <img className="userimg" width="100%" src={User2} alt="" />
              </div>

              <Typography
                mt={1}
                sx={{ color: "white" }}
                variant={matches ? "h5" : "h7"}
              >
                James Hushney
              </Typography>
              <Typography
                my={1}
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              >
                CEO
              </Typography>
              <Typography
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
                px={matches ? 0 : 1}
              >
                Thank you so much Alayzone for always providing me quality work.
                Will hire again."
              </Typography>
            </div>
            <div className="userParrent">
              <div className="img-container">
                <img className="userimg" width="100%" src={User3} alt="" />
              </div>

              <Typography
                mt={1}
                sx={{ color: "white" }}
                variant={matches ? "h5" : "h7"}
              >
                Lura Kinley
              </Typography>
              <Typography
                my={1}
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              >
                Associate HR
              </Typography>
              <Typography
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              
              >
                "Alayzone is an extremely skillful and competent front-end React
                developer team.I would definitely recommend{matches ? <br/> : ""}Alayzone for any
                suitable role related to the front end or react development."
              </Typography>
            </div>
            <div className="userParrent">
              <div className="img-container">
                <img className="userimg" width="100%" src={User4} alt="" />
              </div>

              <Typography
                mt={1}
                sx={{ color: "white" }}
                variant={matches ? "h5" : "h7"}
              >
                Larry Bingham
              </Typography>
              <Typography
                my={1}
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              >
               Manager
              </Typography>
              <Typography
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              
              >
                   "I want to say thank you for delivering great work on my yoga web app. Very timely and professional."
              </Typography>
            </div>
            <div className="userParrent">
              <div className="img-container">
                <img className="userimg" width="100%" src={User5} alt="" />
              </div>

              <Typography
                mt={1}
                sx={{ color: "white" }}
                variant={matches ? "h5" : "h7"}
              >
               Killen Muller

              </Typography>
              <Typography
                my={1}
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              >
               Senior Software Engineer
              </Typography>
              <Typography
                sx={{ color: "white" }}
                variant={matches ? "subtitle1" : "subtitle2"}
              
              >
                   "I did not have my entire plan in my head, making completion of the work difficult for this company. {matches ? <br/> : ""} However, They tolerated me well and did a very good job for me."
              </Typography>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default UserCarousel;

import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Bag from "../../assets/web01.png";
import Web3 from "../../assets/webk.png";
import Chair from "../../assets/web7.png";
import Book from "../../assets/web4.png";
import Watch from "../../assets/web5.png";
import Handfree from "../../assets/web9.png";
import Webs from "../../assets/webs1.png";

const SheduleQuiz = () => {
  return (
    <>
      <Container>
        <Grid container mt={5} spacing={4}>
          <Grid
            item
            md={3}
            xs={12}
            // data-aos="fade-left"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="1000"

          >
            <Grid spacing={4} container>
              <Grid className="containerField" item md={12} xs={6}>
                <img width="100%" src={Bag} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>
              <Grid  className="containerField" item md={12} xs={6}>
                <img width="100%" src={Chair} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>
              <Grid className="containerField"  item md={12} xs={12}>
                <img width="100%" src={Handfree} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
         
            item
            md={6}
            xs={12}
            // data-aos="fade-zoom-in"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="1000"
          >
            
            <Grid className="containerField"  item md={12} xs={12}>
            <img width="100%" src={Book} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>
          
              <Grid className="containerField"  item md={12} xs={12}>
                <img width="100%" src={Watch} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>

          
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            // data-aos="fade-right"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="1000"
          >
            <Grid spacing={4} container>
              <Grid className="containerField"  item md={12} xs={6}>
                <img width="100%" src={Web3} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>
              <Grid className="containerField"  item md={12} xs={6}>
                <img width="100%" src={Webs} alt="" />
                <div class="overlay">
                  <div class="text">Web Design</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SheduleQuiz;

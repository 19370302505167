import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Company1 from "../../assets/company1.jpg";
import Company2 from "../../assets/company2.png";
import Company3 from "../../assets/company2.jpg";
import Company4 from "../../assets/company4.jpg";
import Company5 from "../../assets/company5.jpg";
import Company6 from "../../assets/company6.jpg";


const TrustedCompany = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div style={{backgroundColor:"white"}}>
      <Typography
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
        pt={matches ? 15 : 5}
        variant={matches ? "h3" : "h4"}
        px={matches ? 0 : 2}
        textAlign="center"
        sx={{ color: "#001323", fontWeight: 600 }}
      >
        Trusted by Great People
      </Typography>

      <Typography
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
        mt={3}
        variant="subtitle1"
        px={matches? 0 : 3}
        textAlign="center"
        sx={{ color: "#001323", fontSize: 18 }}
      >
     Trust is one of the most incredible assets. One can either represent the moment of truth an individual,{matches ? <br/> : ""} with the trust they have been depended. Commonly individuals stay devoted to you, {matches ? <br/> : ""}keep the guarantees and can win your trust.
      </Typography>
      <Container>
        <div
          className="companyLogo"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <div className="logoImg">
            <img width="80%" src={Company1} alt="" />
          </div>
          <div className="logoImg">
            <img width="80%" src={Company2} alt="" />
          </div>
          <div className="logoImg">
            <img width="80%" src={Company3} alt="" />
          </div>
          <div className="logoImg">
            <img width="80%" src={Company4} alt="" />
          </div>
          <div className="logoImg">
            <img width="80%" src={Company5} alt="" />
          </div>
          <div className="logoImg">
            <img width="80%" src={Company6} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TrustedCompany;

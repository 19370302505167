import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import VisionHero from "../../assets/VisionHero.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import { Link } from "react-scroll";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  color: "white",
  backgroundColor: "#001322",
  borderColor: "#001322",
  textAlign:"center",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#1583FF",
    borderColor: "#1583FF",
    color: "#001322",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "white",
    color: "black",
    borderColor: "white",
  },
  "&:focus": {},
});

const Hero = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <div className="herobg">
        <Container>
          <Grid pt={matches ? 10 : 3} pb={4} container>
            <Grid
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              sx={{ display: "flex", alignItems: "center" }}
              item
              md={6}
              xs={12}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="HeroHeadTitle"
                    variant={matches ? "h3" : "h4"}
                    sx={{ color: "#001322", fontWeight: 600, cursor:"pointer" }}
                  >
                Our Vision
                  </Typography>
                  <Typography
                    textAlign="center"
                    className="HeroText"
                    my={3}
                    variant="subtitle1"
                    sx={{ color: "#001322", cursor: "pointer" ,lineHeight:1.5 }}
                  >
                   Our vision is to create & maintain good customer relationship, adding in their satisfaction.We know that Customer’s growth is our growth, so we commit our customers to help in achieving their business goals.
                  </Typography>
                </Box>
                <Box textAlign={matches? "center":"center"}   >
                  


                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                    delay={500}
                     >
                    <BootstrapButton   color="inherit">
                      Get Started
                    </BootstrapButton>
                     </Link>

             
                </Box>
              </Box>
            </Grid>
            <Grid
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
             
              item
              md={6}
              xs={12}
            >
            <Box mt={matches ? 0 : 5} textAlign={matches ? "right" : "center"}>
            <img className="heroimg" src={VisionHero} alt="" />
            </Box>
              
            </Grid>
          </Grid>
        </Container>
      </div>
      <div></div>
    </>
  );
};

export default Hero;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "../App.css";
import { Link } from "react-scroll";
import Logo from "../assets/logo.png"




const Header = () => {
  const [navitem,setNavitem] = React.useState("home")
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


   console.log(navitem)
  


 

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <Container>
          <AppBar
            elevation={0}
            sx={{ color: "black", backgroundColor: "white" }}
            position="fixed"
          >
            <Container>
              <Toolbar disableGutters>

                <Link
                 className="logo-link"
                  to="/"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  delay={500}
                >
                  <div className="logo-img">
                  <img  width="100%" src={Logo} alt="logo"/>
                  </div>
                 
                    
                </Link>



                <Box sx={{ flexGrow: 1, display: "flex" }}>
                  <Link
                    to="/"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={() => setNavitem("home")}
                  >
                    <Typography
                      className="header-page"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 3, color: "#001322", cursor: "pointer" ,fontWeight: 600 }}
                      
                    >
                      Home
                    </Typography>
                  </Link>

                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={() => setNavitem("about")}
                  >
                    <Typography
                      className="header-page"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 3, color: "#001322", cursor: "pointer" ,fontWeight: 600 }}
                    >
                      About
                    </Typography>
                  </Link>

                  <Link
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={10}
                    duration={500}
                    delay={500}
                    onClick={() => setNavitem("service")}
                  >
                    <Typography
                      className="header-page"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 3, color: "#001322", cursor: "pointer" ,fontWeight: 600 }}
                    >
                      Services
                    </Typography>
                  </Link>

                  <Link
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    delay={500}
                    onClick={() => setNavitem("portfolio")}
                  >
                    <Typography
                      className="header-page"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 3, color: "#001322", cursor: "pointer" ,fontWeight: 600 }}
                    >
                      Portfolio
                    </Typography>
                  </Link>

                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                    delay={500}
                    onClick={() => setNavitem("contact")}
                  >
                    <Typography
                     className="header-page"
                      // className={`${navitem==='contact'?" navActive":"header-page "}`}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 3, color: "#001322", cursor: "pointer" ,fontWeight: 600 }}
                    >
                      Contact
                    </Typography>
                  </Link>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </Container>
      </div>
    </>
  );
};

export default Header;

import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
// import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-scroll";
import Logo from "../../assets/logo.png"
import { Typography } from "@mui/material";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const HeaderDrawerResponsive = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          elevation={0}
          sx={{ color: "black", backgroundColor: "white" }}
          position="fixed"
          open={open}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 7, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Link
                 className="logo-link"
                  to="/"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  delay={500}
                >
                  <div className="logo-img">
                  <img  width="250%" src={Logo} alt="logo"/>
                  </div>
                 
                    
                </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <Link 
                    to="/"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={handleDrawerClose}
              >
              <ListItemText> {<Typography variant="h5" style={{ color: '#001322' , fontWeight:500, fontSize:"20px" }}>Home</Typography>}</ListItemText>
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <Link 
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={handleDrawerClose}
              >
              <ListItemText>{<Typography variant="h5" style={{ color: '#001322' , fontWeight:500, fontSize:"20px" }}>About</Typography>}</ListItemText>
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <Link 
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={handleDrawerClose}
              >
              <ListItemText>{<Typography variant="h5" style={{ color: '#001322' , fontWeight:500, fontSize:"20px" }}>Services</Typography>}</ListItemText>
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <Link 
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={handleDrawerClose}
              >
              <ListItemText>{<Typography variant="h5" style={{ color: '#001322' , fontWeight:500, fontSize:"20px" }}>Portfolio</Typography>}</ListItemText>
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <Link 
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    delay={500}
                    onClick={handleDrawerClose}
              >
              <ListItemText>{<Typography variant="h5" style={{ color: '#001322' , fontWeight:500, fontSize:"20px" }}>Contact</Typography>}</ListItemText>
              </Link>
            </ListItem>


          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
        </Main>
      </Box>
    </>
  );
};

export default HeaderDrawerResponsive;

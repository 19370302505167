import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Aboutimg from "../../assets/CreativeAbout.png";
import Aboutimgm from "../../assets/CreativeAboutm.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AboutQuizBoz = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <div style={{ backgroundColor: "#F7F7F7" }} id="about">
        <Container mt={10}>
          <Grid direction={matches ?  "" : "column-reverse"} spacing={4} container>
          <Grid className="about-img-grid"
              data-aos="fade-left"
              data-aos-offset="400"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              item
              md={6}
              xs={12}
            >
              <img width="100%"  src={matches ? Aboutimg : Aboutimgm } alt="" />
            </Grid>
            <Grid
              data-aos="fade-right"
              data-aos-offset="400"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              md={6}
              xs={12}
              mb={matches ? 0 : 5}
            >
              <div>
                <Typography
               variant={matches ? "h3" : "h4"}
                  textAlign={matches ? "left" : "center"}
                  sx={{ color: "#001322", fontWeight: 600 }}
                >
                  About Us
                </Typography>
                <Typography
                  variant="subtitle1"
                  textAlign={matches ? "left" : "center"}
                  my={3}
                  sx={{ color: "#001322" ,lineHeight:1.5 }}
                >
                  We value our clients. Our client’s success is our success. We
                  provide the best solution achieving client requirements
                  satisfaction. We try to build a long term relationship with
                  our clients.
                </Typography>
                <Typography
                  mt={3}
                  variant="subtitle1"
                  textAlign={matches ? "left" : "center"}
                  sx={{ color: "#001322" , lineHeight:1.5  }}
                >
                  Our qualified staff has the knowledge and experience to tackle
                  any problem. We’ll work directly with your company to
                  completely understand your situation, make intelligent
                  recommendations, and implement a customized solution to fit
                  the needs of your business.
                </Typography>
              </div>
            </Grid>

          </Grid>
        </Container>
      </div>
    </>
  );
};

export default AboutQuizBoz;
